//
//
//
//
//

import { mapGetters } from "vuex";
export default {
  components: {
    CarrelloDesktop: () =>
      import("@/components/carrello/desktop/carrello_desktop_main.vue"),
    CarrelloMobile: () =>
      import("@/components/carrello/mobile/carrello_mobile_main.vue"),
  },
  computed: {
    ...mapGetters({
      carrello: "get_lista_carrello",
    }),
  },
};
